import { useRouter } from "next/router";
import { useEffect } from "react";

import {
  type AnalyticsConfig,
  type ScreenViewProperties,
  analyticsService,
} from "@medbillai/app/lib/analytics";

export function useAnalyticsService() {
  const { pathname, query } = useRouter();

  // Initialize analytics service on mount
  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY &&
      process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL
    ) {
      const config: AnalyticsConfig = {
        writeKey: process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY,
        dataPlaneUrl: process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL,
        debug: process.env.NEXT_PUBLIC_RUDDERSTACK_DEBUG === "true",
      };
      void analyticsService.initialize(config);
    } else {
      console.error("Analytics configuration is missing.");
    }
  }, []);

  // Track screen view whenever pathname or query changes
  useEffect(() => {
    if (analyticsService && pathname) {
      analyticsService.trackScreenView(pathname, query as ScreenViewProperties);
    }
  }, [pathname, query]);
}
