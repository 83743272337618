import type React from "react";
import { createContext, useContext, useMemo, useState } from "react";

import { useToastController } from "@tamagui/toast";

import { AlertDialogNotification } from "@medbillai/ui";

export type AlertType = "success" | "error" | "warning";
export type Alert = {
  type: AlertType;
  message: string;
  duration?: number;
};

export type AlertDialog = {
  title: string;
  message: string;
  acceptText?: string;
  onAccept?: () => void;
  // defaults to false
  cancellable?: boolean;
  cancelThin?: boolean;
};

export interface NotificationContextInterface {
  showAlert: (alert: Alert) => void;
  showAlertDialog: (alert: AlertDialog) => void;
  isAlertDialogOpen: boolean;
}

export const initialContext: NotificationContextInterface = {
  showAlert: () => {
    console.error("Context not initialized");
  },
  showAlertDialog: () => {
    console.error("Context not initialized");
  },
  isAlertDialogOpen: false,
};

export const NotificationContext =
  createContext<NotificationContextInterface>(initialContext);

export interface NotificationProviderProps {
  children?: React.ReactNode;
}

const alertTitles: Record<AlertType, string> = {
  success: "Amazing!",
  error: "Oops!",
  warning: "Warning",
};

export const NotificationProvider = ({
  children,
}: NotificationProviderProps) => {
  // Initializing with an empty alert to mount the component
  const [alertDialog, setAlertDialog] = useState<AlertDialog>({
    title: "",
    message: "",
  });
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const toastController = useToastController();

  const showAlert = ({ type, message, duration }: Alert) => {
    const title = alertTitles[type];
    toastController.show(title, {
      message,
      duration: duration || 5000,
      burntOptions: {
        haptic: type,
      },
    });
  };

  const showAlertDialog = ({
    title,
    message,
    onAccept,
    acceptText = "Ok",
    cancellable = false,
    cancelThin = false,
  }: AlertDialog) => {
    setAlertDialog({
      title,
      message,
      onAccept,
      acceptText,
      cancellable,
      cancelThin,
    });
    setOpenAlertDialog(true);
  };

  const contextValue = useMemo<NotificationContextInterface>(
    () => ({
      showAlert,
      showAlertDialog,
      isAlertDialogOpen: openAlertDialog,
    }),
    [showAlert, showAlertDialog, openAlertDialog],
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      <AlertDialogNotification
        open={openAlertDialog}
        onOpenChange={() => {
          setOpenAlertDialog(false);
        }}
        {...alertDialog}
      />
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = (
  context = NotificationContext,
): NotificationContextInterface => useContext(context);
