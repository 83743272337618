import type { NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import "raf/polyfill";
import { type ReactElement, type ReactNode, useCallback } from "react";
import type { SolitoAppProps } from "solito";

import "@tamagui/core/reset.css";
import "@tamagui/font-inter/css/400.css";
import "@tamagui/font-inter/css/700.css";
import {
  type ColorScheme,
  NextThemeProvider,
  useRootTheme,
} from "@tamagui/next-theme";

import { ShadowLoginBanner } from "@medbillai/app/components/ShadowLoginBanner";
import { AuthProvider } from "@medbillai/app/lib/auth";
import { EmailLinkAnalyticsTracker } from "@medbillai/app/lib/email-tracking/emailLinkAnalyticsTracker";
import { Provider } from "@medbillai/app/provider";

import { loginUrl } from "@medbillai/utils/urls";

import "../public/static/fonts.css";
import { useAnalyticsService } from "../utils/UseAnalyticsService";
import { useObservability } from "../utils/useObservability";

if (process.env.NODE_ENV === "production") {
  require("../public/static/tamagui.css");
}

// Ref: https://nextjs.org/docs/pages/building-your-application/routing/pages-and-layouts
export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

function MyApp({ Component, pageProps }: SolitoAppProps) {
  useObservability();
  useAnalyticsService();
  const getLayout = Component.getLayout || (page => page);
  const [_theme, setTheme] = useRootTheme({ fallback: "light" });
  const { push } = useRouter();

  const onAuthError = useCallback(async () => {
    // TODO: Pass redirect path?
    await push(loginUrl({ forceLogout: true }));
  }, [push]);

  // Since window is globally available and immutable in this context,
  // you won't run into stale closure issues by not including it in the dependency array
  const authRedirectUri = useCallback(() => {
    if (typeof window === "undefined") {
      console.log("No window object");
    }
    const currentUrl = new URL(
      typeof window === "undefined" ? "" : window.location.href,
    );
    return currentUrl.origin + "/authenticate";
  }, []);

  return (
    <>
      <Head>
        <title>Granted</title>
        <meta name="description" content="Granted" />
        <link rel="icon" href="/favicon.ico" />
        {/*
          Note that only the core typography fonts used on the home screen need
          to be preloaded! Each font added here will delay initial rendering of
          the screen, which prevents a "flash" of an incorrect font, but creates
          initial latency. Additional non-core fonts will be loaded on-demand.
        */}
        <link
          rel="preload"
          href="/static/fonts/kh-teka/KHTeka-Light.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/static/fonts/kh-teka/KHTeka-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/static/fonts/kh-teka/KHTeka-Medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/static/fonts/nc-kobyla/NCKobyla-Light.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>
      <NextThemeProvider
        forcedTheme="light"
        defaultTheme="light"
        enableSystem={false}
        onChangeTheme={next => {
          setTheme(next as ColorScheme);
        }}
      >
        <Provider
          apolloPath="/api/graphql"
          apolloWsPath="/api/subscriptions"
          onAuthError={onAuthError}
        >
          <AuthProvider authRedirectUri={authRedirectUri}>
            <EmailLinkAnalyticsTracker />
            <ShadowLoginBanner />
            {getLayout(<Component {...pageProps} />)}
          </AuthProvider>
        </Provider>
      </NextThemeProvider>
    </>
  );
}

export default MyApp;
