import { RetryLink } from "@apollo/client/link/retry";

// Retries in the case of network errors. GraphQL errors are handled by
// the error link.
// Ref: https://www.apollographql.com/docs/react/api/link/apollo-link-retry/

const ignoreRetryOperations = ["Logout"];

export const retryLink = new RetryLink({
  delay: {
    initial: 200,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (_, operation) => {
      // Per the docs (linked above), only network errors are retried.

      if (ignoreRetryOperations.includes(operation.operationName)) {
        return false;
      }

      // TODO: Are there other instances in which we should not retry? We don't
      // want to repeat non-idempotent actions that are partially completing. A
      // network error usually indicates a connectivity issue, though, so it's
      // likely the request never made it to the server.

      console.debug(`Retrying operation ${operation.operationName}`);
      return true;
    },
  },
});
